import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _351bd09d = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _f1f05f94 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _51cc46ff = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _001cff86 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _2d26c235 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _61be29f6 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _0ce2b0b8 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _5dc79176 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _4698b6af = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _bc1ebb64 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _3a207e0b = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _0bf794cd = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _76a6a5f6 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _5cd68c92 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _02ecbb96 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _37795196 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _c0d47512 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _273d2ffc = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _77a5fd12 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _793e3195 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _a15b937a = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _09104428 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _7e3afc39 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _3a628627 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _07fdc3aa = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _333dc19a = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _98a92124 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _524d08a0 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _083f9692 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _67aa01e6 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _6d69acaa = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _d8b20636 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _7ddfea23 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _6b4e75ff = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _7792ee4d = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _58869924 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _dbc2b5da = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _272a859c = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _671b437a = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _14c0b35a = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _d45067ca = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _6ce68984 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _351bd09d,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _f1f05f94,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _51cc46ff,
    name: "help"
  }, {
    path: "/verify-email",
    component: _001cff86,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _2d26c235,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _61be29f6,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _0ce2b0b8,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _5dc79176,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _4698b6af,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _bc1ebb64,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _3a207e0b,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _0bf794cd,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _76a6a5f6,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _5cd68c92,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _02ecbb96,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _37795196,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _c0d47512,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _273d2ffc,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _77a5fd12,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _793e3195,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _a15b937a,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _09104428,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _7e3afc39,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _3a628627,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _07fdc3aa,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _333dc19a,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _98a92124,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _524d08a0,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _083f9692,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _67aa01e6,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _6d69acaa,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _d8b20636,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _7ddfea23,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _6b4e75ff,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _7792ee4d,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _58869924,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _dbc2b5da,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _272a859c,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _671b437a,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _14c0b35a,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _d45067ca,
    name: "blog-slug"
  }, {
    path: "/",
    component: _6ce68984,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
